b,
strong,
.bold {
  font-weight: 600;
}

p {
  margin: 0;
}

a {
  &.link {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: left;
    color: var(--grey);
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

.input-padding {
  padding: var(--input-padding);
}

.odds {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--yellow);
  white-space: nowrap;
}

.verification-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;

  .verification-input {
    margin-right: 20px;
    overflow: hidden;
    vertical-align: middle;
    margin-top: 8px;

    input[type="file"] {
      display: none;
    }

    label {
      width: 200px;
      display: inline-block;
      line-height: 22px;
      height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-button {
      display: inline-block;
      background-color: #665f62;
      cursor: pointer;
      color: #dedede;
      text-align: center;
      padding: 0 15px;
      margin-right: 12px;
    }
  }

  .verification-button {
    button {
      cursor: pointer;
      border: 0px;
      color: #dedede;
      line-height: 20px;
      text-align: center;
      background: url("/assets/images/r.png") left top repeat-x;
    }
  }
}

.stt {
  display: inline-block;
  color: #dfdfdf;
  // padding: 0 50px 0 12px;
  line-height: 22px;
  height: 22px;
  // background: url('/assets/images/stt.png') right top no-repeat;
  // text-transform: uppercase;
  word-break: normal;
  opacity: 0.6;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: var(--grey);
}

.select-race {
  display: block;
  float: left;
  border: 2px solid transparent;
  background-color: #ccc;
  cursor: pointer;
  user-select: none;

  &.selected {
    border-color: #bb0909;
  }

  &.not-available {
    background: url("/assets/images/misc/bds.png");
    color: #767676;
  }
}

.result-track {
  .info-text {
    margin-top: 1px;
    margin-bottom: 1px;
    display: block;
  }
}

.keep-all {
  word-break: keep-all;
}

.track-bet-race {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px 2px 0;
  padding: 0 8px;
  line-height: 18px;
  height: 24px;
  background: #d5d5d5 url("/assets/images/misc/bd.png") left top repeat-x;
  color: #585858;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  border-radius: 3px;
  white-space: nowrap;
}

.generic-hover {
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.track-bet-races {
  .track-bet-race {
    float: left;
  }
}

.toto-select {
  width: 100%;
  margin: 5px 0px;
  height: var(--input-min-height);
  outline: none;
  border: 1px solid #969696;
  border-radius: 3px;
}

.text-center {
  text-align: center !important;
}

.uppercase {
  text-transform: uppercase;
}

.no-display {
  display: none !important;
}

.no-padding {
  padding: 0 !important;
}

.start-number,
.position-number {
  text-align: center;
  border-radius: 14px;
  color: var(--black-1);
  font-weight: bold;
  font-size: 14px;
  line-height: 1.75;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
}

.start-number {
  background-color: var(--white);
}

.position-number {
  background-color: var(--yellow);
}

.cdk-overlay-container {
  z-index: 1005 !important;
}

.cdk-overlay-dark-backdrop {
  opacity: 0.8 !important;
  background-color: var(--black-1);
}

// ASKLY IFRAME

#tw-chat-root {
  z-index: 1008 !important;

  iframe {
    // width: 0 !important;
    // height: 0 !important;
    z-index: 1008 !important;
  }
}
