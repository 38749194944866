
swiper.toto-swiper {
  width: 100%;
  height: 100%;

  .swiper-button-next,
  .swiper-button-prev {
    height: 100%;
    top: 8px;
    background-color: var(--app-background-color);
  }

  .swiper-button-prev {
    left: -1px;
  }

  .swiper-button-next {
    right: -1px;
  }

  .swiper-slide {
    width: auto !important;
  }

  .swiper-button-disabled {
    display: none;
  }

  .sport-option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    padding: 5px 15px 5px 10px;

    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: center;
    color: var(--white);
    user-select: none;
    filter: var(--filter-grayscale);
    text-decoration: none;

    &:hover {
      // filter: none;
      opacity: 0.8;
    }

    &.active {
      filter: none;
      border-color: var(--light-green);
    }
  }
}