@use "src/app/styles/breakpoints";

h1 {
  font-size: 44px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  color: #dedede;
}

h2 {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  color: #dedede;
}

h3 {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  color: #dedede;
}

h4 {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  color: #dedede;
}

h5 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  margin: 0;
  color: #dedede;
}

.line-clamp-1 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.line-clamp-2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.sm-grey {
  font-size: 9px;
  color: #888;
  white-space: nowrap;
}

.section-header {
  display: flex;
  font-size: 16px;
  letter-spacing: 0.23px;
  text-align: left;
  color: var(--grey);
  opacity: 0.6;
  margin-bottom: 10px;
}

.monospace {
  font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", "Cabin", Helvetica, Arial, sans-serif;
}

.text-align-start {
  text-align: start !important;
}

.text-align-end {
  text-align: end !important;
}

.text-align-center {
  text-align: center !important;
}

/* MOBILE */
@media screen and (max-width: breakpoints.$md) {

    h1 {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #dedede;
      }
      
      
      h2 {
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: #dedede;
      }
      
      h3 {
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #dedede;
      }
      
      h4 {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #dedede;
      }
      
      h5 {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #dedede;
      }
}