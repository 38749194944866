:root {
    --mdc-theme-primary: var(--theme-color-1);
    --mdc-theme-secondary: var(--theme-color-2);
    --mdc-theme-error: var(--input-error-color);
    --mdc-dialog-container-color: var(--black-borders);

    // SNACKBAR
    --mdc-snackbar-container-color: var(--dark-grey);
    --mdc-snackbar-container-shape: 4px;
    --mdc-shape-small: 4px;

    --mdc-snackbar-supporting-text-font: 'Cabin';
    --mdc-snackbar-supporting-text-font-size: 14px;
    --mdc-snackbar-supporting-text-weight: 400;
    --mdc-snackbar-supporting-text-line-height: 20px;
    --mdc-snackbar-supporting-text-letter-spacing: 0.2px;
    --mdc-snackbar-supporting-text-color: var(--white);
}

.mat-mdc-form-field-flex {
    height: 100%;
}


// TAB
.mat-mdc-tab-group {
    flex: 1;
}

