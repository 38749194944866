form {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    color: var(--grey);
  
    form-field {
      toto-input {
        width: 100%;
      }
    }
  
    .form-title {
      display: flex;
      align-items: baseline;
      justify-content: left;
      margin-bottom: 30px;
      // margin-top: 40px;
      box-sizing: border-box;
      flex-wrap: wrap;
  
      h2 {
        font-size: 32px;
        font-weight: 700;
        font-style: italic;
        text-align: left;
        line-height: normal;
        padding-right: 20px;
        color: var(--grey, #dedede);
      }
  
      h5 {
        font-family: Cabin;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
        color: var(--grey, #dedede);
      }
    }

    .form-filters {
      display: flex;
      flex-direction: column;
      gap: 20px 10px;
    
      .form-filters-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
      
        toto-datepicker {
          width: 210px;
        }
      }
    }

    .form-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
    }
  
  
    .form-footer {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 40px;
    }
  
    .toggle-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      background: var(--dark-grey, #3c3c3c);
      padding: 10px 20px;
      box-sizing: border-box;
      min-height: 57px;
      border-radius: 3px;
      // width: 646px;
  
      .toggle-label {
        color: var(--white, #FFF); 
        margin-right: 10px;
      }
    }
  }