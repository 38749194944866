

.mat-datepicker-content-container {
    background: var(--white);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-cell-content {
    color: rgba(0, 0, 0, 0.87);
    border-color: transparent;
}

.mat-calendar-body-selected {
    border: none;
    background-color: var(--theme-color-1);
    color: #fff;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
    background-color: var(--dirty-purple-8);
}

.mat-calendar-controls {
    .mat-mdc-icon-button {
        padding: 0 !important;
        min-width: 0;
        width: 40px !important;
        height: 40px !important;
        flex-shrink: 0;
        line-height: 40px !important;
        border-radius: 50% !important;
    }
}

.mat-datepicker-toggle {
    .mdc-icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.mat-calendar-table-header {
    text-align: start;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dirty-purple);
    align-items: center;

}

.mdc-text-field-wrapper {
    padding: 0 !important;
}

.mdc-text-field {
    padding-left: 0 !important;
}

/* MOBILE */
@media screen and (min-width: breakpoints.$md) {

    .mat-dialog-container {
        background-color: var(--black-menus);
        padding: 0 !important;
    }

    .mat-datepicker-content-touch {
        overflow: unset;
        margin: 0;
    }
}

.mat-mdc-form-field-icon-suffix {
    display: flex;
}

.mdc-line-ripple {
    display: none;
}

.mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
}