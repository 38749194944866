@use "src/app/styles/breakpoints";

.responsive-iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; // 16:9
  
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  
  

@media screen and (max-width: breakpoints.$md) { 
    .mat-tab-labels {
        flex-direction: column !important;

        .mat-tab-label {
            border-left: 1px solid var(--grey);
            justify-content: flex-start !important;
            border-bottom: 1px solid var(--greyish-brown);
            height: 60px;
        }
    }
    
    .mat-tab-label-active {
        border-left: 2px solid var(--coral) !important;
    }
    
    .mat-ink-bar {
        display: none;
    }

}

@media screen and (max-width: breakpoints.$lg) {

    user-sidebar {
        display: none !important;
    }

    // navigator-desktop {
    //     #logo {
    //         display: none;
    //       }
    // }

}