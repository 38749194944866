:root {

  // Colors
  --white: #fff;
  --watermelon: #f04b54;
  --dark-sea-green: #18a349;
  --red: #da1212;
  --light-red: #f64347;
  --dark-red: #b50a09;
  --bordo: #821010;
  --coral: #f94e4e;
  --turquoise-blue: #00abcd;
  --yellow: #FAA61A;
  --grey: #dedede;
  --grey-2: #d9d9d9;
  --light-grey: #d9d9d9;
  --medium-grey: #9D9D9D;
  --dark-grey: #3c3c3c;
  --dark-grey-2: #333232fa;
  --dark-grey-hover: #555555;
  --greyish-brown: #444;
  --black-1: #111;
  --black-2: #191919;
  --black-3: #292929;
  --black-4: #2a2a2a;
  --black-menus: #212121;
  --black-borders: #303030;
  --purple: #b17af8;
  --green: #49912d;
  --light-green: #9fd356;
  --dark-green: #3b6d2d;
  --very-dark-green: #172613;

  --team-one-color: var(--light-red);
  --team-two-color: var(--turquoise-blue);

  // Other
  --filter-grayscale: grayscale(1) brightness(1.5);
  --app-background-color: var(--black-1);
}

.yellow {
  color: var(--yellow);
}

.red {
  color: var(--red);
}

.grey {
  color: var(--grey);
}

.light-red {
  color: var(--light-red);
}

.tangerine {
  color: var(--tangerine);
}

.theme-color-1 {
  color: var(--theme-color-1);
}

.theme-color-2 {
  color: var(--theme-color-1);
}