.mat-mdc-tab-group {
    height: 100%;
    background: inherit;
    &.sticky-header {
        .mat-mdc-tab-header {
            background: inherit;
            top: 0;
            z-index: 1000; 
            position: sticky;
            position: -webkit-sticky; /* macOS/iOS Safari */    
        }
    }
}

.mat-mdc-tab-header-pagination-disabled {
    display: none !important;
}

.mat-mdc-tab-header-pagination {
    color: white;
}


.tab-container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

.mat-mdc-tab-group {
    flex: 1;
}

.mat-mdc-tab-nav-bar {
    &.mat-mdc-tab-header {
        max-width: 100%;
    }
}

.mat-mdc-tab-labels {
    flex-wrap: wrap;
    border-bottom: 1px solid var(--black-3);

    .mat-mdc-tab-label {
        opacity: 1;
        letter-spacing: 0.23px;
        color: var(--white);
    }
}

.mat-mdc-tab-links {


    .mdc-tab {
        text-decoration: none;
        &:not(.last-of-type) {
            margin-right: 20px;
        }
    }
}

.mat-mdc-tab-group,
.mat-mdc-tab-label, .mat-mdc-tab-link {
    font-family: 'Cabin', sans-serif;
    color: var(--white);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: var(--light-green);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: var(--white);
}

.mat-mdc-tab-body-wrapper {
    // margin-top: 20px;
    // margin-left: -20px;
    // margin-right: -20px;
    // padding: 0 20px;
    width: 100%;
    padding: 20px 0;

    .mat-mdc-tab-body {
    //   margin: 0 -20px;
    //   padding: 0 20px;

      .mat-mdc-tab-body-content {
        // margin: 0 -20px;
        // padding: 0 20px;
      }
    }
}

.no-tab-header .mat-mdc-tab-header {
    display: none;
}

.mdc-tab {
    padding: 9px 5px !important;
    align-items: center !important;
    font-family: 'Cabin';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: center;
    color: var(--white);
}

.mdc-tab__ripple::before, .mat-mdc-tab .mat-ripple-element, .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-link .mat-ripple-element {
    background-color: var(--greyish-brown) !important;
}