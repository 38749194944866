@use "src/app/styles/breakpoints";

.sticky-table {
    display: flex;
    flex: 1;
    flex-direction: column;
  
    .sticky-table-body {
      display: flex;
      flex: 1;
      position: relative;
      width: 100%;
      overflow-x: auto;
      will-change: transform;
  
      .mat-mdc-table {
        position: absolute;
  
        .mat-header-row {
          position: sticky;
          top: 0;
          background: white;
          opacity: 1;
          z-index: 10000000;
          .mat-header-cell {
            background: var(--dirty-purple-8);
  
            &.mat-table-sticky {
              background: white;
  
              &::before {
                content: '';
                position: absolute;
                background: var(--dirty-purple-8);
                height: 100%;
                width: 100%;
                left: 0;
                // margin-left: -12px;
              }
            }
          }
        }
  
        .mat-mdc-row {
          &.table-row-hover {
            &:hover {
              .mat-mdc-cell {
                &.mat-table-sticky {
                  &::before {
                    background: var(--white);
                  }
                  &::after {
                    background: var(--row-background-hover);
                  }
                }
              }
            }
          }
  
          &.invalid {
            .mat-mdc-cell {
              &.mat-table-sticky {
                &::before {
                  background: var(--row-background-invalid);
                }
              }
            }
          }
  
          &:hover {
            .mat-mdc-cell {
              &.mat-table-sticky {
                &::after {
                  content: '';
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  left: 0;
                  // margin-left: -12px;
                  z-index: -1;
                  background-repeat: no-repeat;
                }
              }
            }
          }
  
          .mat-mdc-cell {
            &.mat-table-sticky {
              &::before {
                content: '';
                position: absolute;
                background: var(--white);
                height: 100%;
                width: 100%;
                left: 0;
                // margin-left: -12px;
                z-index: -1;
                box-shadow: inherit;
                background-repeat: no-repeat;
              }
  
            }
          }
        }
      }
    }
  
    .sticky-table-footer {
      display: flex;
      flex: 0;
      justify-content: flex-end;
      align-items: center;
    }
  }


table {
    border-collapse: collapse;
    thead {
        // border-bottom: 1px solid var(--black-borders);
    }

    td, th {
        text-align: left;
        padding: 12px 6px;

        &:first-of-type {
            padding-left: 15px;
        }

        &:last-of-type {
            padding-right: 15px;
        }
    }
}

.table-wrapper {
    // margin-top: 20px;

    table {
        min-width: 100%;

        thead {
            min-width: 100%;
        }

        th {
            text-align: left;
            word-break: keep-all;

            opacity: 0.6;
            font-size: 16px;
            letter-spacing: 0.23px;
            color: var(--grey);

            &:not(:last-of-type) {
                padding-right: 6px;
            }
        }

    }
}

.toto-table {
    min-width: 100%;
    border-collapse: collapse;

    thead {
        border-bottom: solid 1px var(--black-3);
    }
    tbody {
        > tr {
            border-bottom: solid 1px #2a2a2a;
        }
        tr {
            &.selected,
            &:hover {
                color: #eee;
            }
        }
    }
    td, th {
        padding: 8px;
    }
    th {
        white-space: nowrap;
        // color: #555;
        text-align: left;
        padding-bottom: 6px;
        // white-space: nowrap;
        line-height: 18px;
        text-align: left;
     }
 }

 .valign-top {
     td {
        vertical-align: top;
     }
 }

 .valign-center {
     td {
        vertical-align: center;
     }
 }

 .data-table-container {
    display: flex;
    flex: 1;
    width: 100%;
    position: relative;
    overflow: auto;
    min-height: 500px;

    no-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .mat-mdc-row {
        // border-bottom: 1px solid #444;
        min-height: 64px;
        
        .mat-mdc-cell {
            // font-size: 12px;
            border-bottom: 1px solid #444;
        }

        &:hover:not(.expanded-row) {

          .mat-mdc-cell {
            background-color: rgba(52, 52, 52, 0.5);
          }
        }
    }

    .loading-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0000001c;

        .loading-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

}


.mat-mdc-form-field.mat-focused .mat-mdc-form-field-ripple {
    background-color: #444;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-select-arrow,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #444;
}

.mat-mdc-header-row {
    align-items: flex-start;
    min-height: 25px;
}

 .mat-mdc-header-cell,
 .mat-mdc-cell {
    padding: 0 4px;
 }

.mat-mdc-header-cell {
    flex-direction: column;
    align-items: flex-start;

    toto-select {
        margin: 0;
    }
}

.cell-center {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  align-self: center;
}

.header-cell-end {
  align-items: flex-end !important;
}

.cell-end {
  justify-content: flex-end !important;
}

.header-cell-center {
  align-items: center !important;
}

.cell-center {
  justify-content: center !important;
}



 /* DESKTOP */
//  @media screen and (min-width: breakpoints.$md) {

//  }

/* MOBILE */
@media screen and (max-width: breakpoints.$md) { 
    .mobile-table {
        thead {
            display: none;
        }
        tr {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 8px;
            margin-bottom: 8px;
            background: url('/assets/images/misc/b.png');
    
            td {
                display: flex;
                flex-direction: row;
                align-items: center;
                line-height: 1em;
                flex: 0 !important;
                padding: 8px;
                padding-left: 2px !important;
                flex-wrap: wrap;
                
                span.mobile {
                    margin-right: 6px;
                }
            }
    
        }
    }

}

.td-end {
    text-align: end !important;
    padding-right: 8px;
}

.th-end {
    align-self: flex-end !important;
    text-align: end !important;
    padding-right: 8px;
}

.td-center {
    text-align: center !important;
    padding-right: 8px;
}

.th-center {
    align-self: center !important;
    text-align: center !important;
    padding-right: 8px;
}

.th-start {
    align-self: flex-start !important;
    text-align: start !important;
    padding-right: 8px;
}
