$color: var(--white);

.mat-mdc-table {
  .mdc-data-table__header-cell,
  .mdc-data-table__cell {
    padding: 0px 16px 0 16px;
  }
}

.mat-mdc-table,
.mat-mdc-paginator {
  width: 100%;
  background: transparent;
  color: white;
}

.mat-sort-header-content {
  white-space: nowrap;
}

mat-select {
  .mat-select-value,
  .mat-select-arrow {
    color: white;
  }
}

.mat-paginator-icon {
  fill: $color !important;
}

.mat-sort-header-arrow {
  transform: none !important;
  transition: none !important;
  margin-right: -12px !important; // Fix cell-end and cell-center alignment
}

mat-header-row,
mat-row,
mat-header-cell {
  color: $color !important;
}

mat-header-cell,
mat-cell {
  color: $color !important;
  border-bottom: none !important;
}

mat-cell:first-of-type,
mat-header-cell:first-of-type,
mat-footer-cell:first-of-type {
  padding-left: 0 !important;
}

mat-cell:last-of-type,
mat-header-cell:last-of-type,
mat-footer-cell:last-of-type {
  padding-right: 0 !important;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: $color;
}

.mat-mdc-option .mdc-list-item__primary-text {
}

.mat-mdc-form-field {
  &.mat-mdc-paginator-page-size-select {
    min-width: 100px;
  }
}

.mat-mdc-paginator,
.mat-mdc-select-value {
  color: $color !important;
}


.mat-mdc-paginator-range-label {
  margin: 0 24px 0 8px !important;
}