input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-label {
  margin-bottom: 5px;
  white-space: nowrap;
}

.input-readonly {
  white-space: pre-line;
  word-break: break-word;
  text-overflow: ellipsis;
  border: none;
  text-align: inherit;
  background: inherit !important;
  padding: var(--input-padding);
}

.input-padding {
  padding: var(--input-padding);
}
