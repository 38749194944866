:root {
  --swiper-navigation-color: var(--white);
  --swiper-navigation-size: 14px;
  --swiper-navigation-background-color: #11111160;
  --swiper-navigation-background-color-hover: #111111;
}

swiper {
  width: 100%;
  height: 100%;

  .swiper-button-prev,
  .swiper-button-next {
    width: 36px;
    height: 44px;
    background-color: var(--swiper-navigation-background-color);
    border-radius: 3px;

    &:hover {
      background-color: var(--swiper-navigation-background-color-hover);
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .swiper-slide {
    width: auto !important;
  }

  .swiper-button-disabled {
    display: none;
  }
}

.banners-swiper {
  width: 100%;

  .swiper-pagination-bullet {
    background: var(--grey);
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }

  .swiper-slide {
    min-width: 100%;
    width: 100% !important;
  }
}


 /* DESKTOP */
 @media screen and (max-width: breakpoints.$sm) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
 }