.ql-container {
    font-family: 'Cabin' !important;
    font-size: 14px;
    letter-spacing: 0.2px;
}

.ql-snow {
    a {
        color: var(--white);

        &:hover {
            opacity: 0.8;
        }
    }
}