@use "src/app/styles/breakpoints";

.odds-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    background-color: var(--dark-grey);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: center;
    color: var(--yellow);
    white-space: nowrap;
    user-select: none;
    text-overflow: ellipsis;
    // flex: 1;
    border-radius: 3px;

    &::after {
      content: '';
      width: 5px;
    }

    &.selected {
      background-color: var(--dark-green);
      color: var(--white);

      .handicap {
        opacity: 1;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: var(--dark-grey-hover);
      color: var(--white);
    }
}




/* MOBILE */
@media screen and (max-width: breakpoints.$md) {

    .odds-box {
        height: 45px;
    }
    
  }