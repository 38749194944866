@use "breakpoints";

:root {
  --font-family: "Cabin", sans-serif;

  --content-padding: 40px 90px;
  --content-padding-bottom-1: 50px;

  --layout-max-width: 5000px;
  --content-max-width: 1200px;
  --content-max-width-1600: 1600px;
  --content-max-width-user: 650px;
  --content-max-width-transaction-log: 800px;
  --content-max-width-predictions: 750px;
  --content-side-width: 260px;
  --form-data-padding-left: 30px;
  --form-data-padding-right: 1.5em;
  --form-data-min-width: 200px;
  
  --input-min-height: 45px;
  --input-height: 45px;
  --input-border: 1px solid transparent;
  --input-font-size: 16px;
  --input-focus-border: 1px solid var(--grey-2);
  --input-background: var(--dark-grey);
  --input-error-border: 1px solid var(--light-red);
  --input-error-color: var(--input-color);
  --input-border-radius: 3px;
  --input-border-transition: border 0.2s;
  --input-padding: 6px 10px;
  --input-color: var(--grey);
  --input-placeholder-color: var(--light-grey); // With opacity 0.6

  --swiper-pagination-color: var(--white);

  --view-spinner-size: 60px;
  --view-spinner-background: var(--app-background-color);
  --view-spinner-path-color: var(--grey);
  --view-spinner-color: var(--theme-color-1);

  // NAV
  --nav-top-height: 50px;
  --nav-bottom-height: 75px;
  
  // SCROLLBAR
  --scrollbar-width: 10px;
  --scrollbar-track-color: var(--black-borders);
  --scrollbar-thumb-color: var(--dark-grey);
  --scrollbar-thumb-hover-color: var(--theme-color-1);

  // BUTTONS
  --input-height-lg: 50px;
  --input-height-md: var(--input-min-height);
  --input-height-sm: 38px;
  --input-height-xs: 32px;

  // MARKETS
  --table-event-width: 200px;
  --table-event-min-width: 200px;
  --table-event-max-width: 200px;

  --table-action-height: 40px;
  --table-action-width: 40px;
  --table-action-padding: 10px;
  --table-event-competitors-font-size: 14px;
  --table-market-min-width: 250px;
  --table-market-max-width: 500px;
  --table-market-width: 250px;

  // COL HORIZONTAL SPACING IN ROW
  --col-x-spacing: 10px;

  // FOOTER
  --footer-padding: 30px 75px;

  // LIVEBET
  --livebet-nav-height: 65px;
  --livebet-widgets-height: var(--content-view-height);
  --livebet-widgets-width: 500px;

  --live-score-main-height: 18px;

}

// DESKTOP
@media screen and (min-width: breakpoints.$lg) {
  :root {
    --nav-height: calc(var(--nav-top-height) + var(--nav-bottom-height));
    --content-padding: 40px 90px 90px 90px;
    --sport-odds-height: 38px;
    --tickets-container-height: 50px;
    --input-height: 45px;
    --input-min-height: 45px;

    --content-view-height: calc(100dvh - var(--nav-height));

    --sport-odds-height: 38px;

    --input-height-xl: 60px;
    --input-height-lg: 50px;
    --input-height-md: var(--input-min-height);
    --input-height-sm: 38px;
    --input-height-xs: 32px;

    --col-x-spacing: 10px;
  }
}

// TABLET
@media screen and (min-width: breakpoints.$sm) and (max-width: breakpoints.$lg) {
  :root {
    --nav-height: var(--nav-top-height);
    --content-padding: 20px 20px 40px 20px;
    --sport-odds-height: 45px;
    --nav-drawer-max-width: 300px;
    --tickets-container-height: 50px;
    --input-height: 50px;
    --input-min-height: 50px;

    --sport-odds-height: 45px;

    --content-view-height: calc(100dvh - var(--nav-height));
    --input-height-xl: 60px;
    --input-height-lg: 50px;
    --input-height-md: var(--input-min-height);
    --input-height-sm: 38px;
    --input-height-xs: 32px;

    --col-x-spacing: 9px;


    // LIVEBET
    --livebet-widgets-height: 500px;
    --livebet-widgets-width: 100%;
    --live-score-main-height: 22px;

    //

    --footer-padding: 30px 20px;

    --table-event-width: 180px;
    --table-event-min-width: 180px;
    --table-event-max-width: 180px;
    --table-market-min-width: 200px;
    --table-market-max-width: 250px;
  }
}

// MOBILE
@media screen and (max-width: breakpoints.$sm) {
  :root {
    --nav-height: var(--nav-top-height);
    --content-padding: 20px 20px 40px 20px;
    --sport-odds-height: 45px;
    --nav-drawer-max-width: 300px;
    --tickets-container-height: 50px;
    --input-height: 50px;
    --input-min-height: 50px;

    --sport-odds-height: 45px;

    --content-view-height: calc(100dvh - var(--nav-height));
    --input-height-xl: 60px;
    --input-height-lg: 50px;
    --input-height-md: var(--input-min-height);
    --input-height-sm: 38px;
    --input-height-xs: 32px;

    --col-x-spacing: 6px;
    --footer-padding: 30px 20px;

    // LIVEBET
    --livebet-widgets-height: 350px;
    --livebet-widgets-width: 100%;
    --live-score-main-height: 22px;

    --table-event-width: 120px;
    --table-event-min-width: 120px;
    --table-event-max-width: 120px;
    --table-market-min-width: 125px;
    --table-market-max-width: 125px;
    
  }
}

@media screen and (max-width: 380px) {
  :root {
   
    // LIVEBET
    --table-event-width: 100px;
    --table-event-min-width: 100px;
    --table-event-max-width: 100px;
  }
}

@media screen and (max-width: 350px) {
  :root {
   
    // LIVEBET
    --table-event-width: 80px;
    --table-event-min-width: 80px;
    --table-event-max-width: 80px;
  }
}


