
.button {
    border: 0;
    background: linear-gradient(
0deg
, #665F62 56.07%, rgba(222, 222, 222, 0.5) 179.96%);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;

    &:hover {
        background: linear-gradient(
            180deg
            , #D40E0E 35.38%, #A90606 100%);
                text-decoration: none;
    }
}

.red-button {
    background: #900000 url('/assets/images/r.png') left top repeat-x;
}