svg-icon {
    fill: unset !important;
}


.icon {
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    flex-grow: 0;
    flex-shrink: 0;
}

.filter-active-icon {
    @extend .icon;
    background-image: url('/assets/images/icons/filter-active.svg');
    height: 24px;
    width: 24px;
}

.filter-inactive-icon {
    @extend .icon;
    background-image: url('/assets/images/icons/filter-inactive.svg');
    height: 24px;
    width: 24px;
}

.bet-icon {
    @extend .icon;
    background-image: url('/assets/images/icons/bet.svg');
    width: 14px;
    height: 16px;
}

.bet-mask-icon {
    @extend .icon;
    -webkit-mask-image: url('/assets/images/icons/bet.svg');
    mask-image: url('/assets/images/icons/bet.svg');
    width: 14px;
    height: 16px;
}

.tick-active-icon {
    @extend .icon;
    background-image: url('/assets/images/icons/tick-active.svg');
    width: 14px;
    height: 14px;
}

.tick-active-white-icon {
    @extend .icon;
    background-image: url('/assets/images/icons/tick-active-white.svg');
    width: 14px;
    height: 14px;
}

.tick-inactive-icon {
    @extend .icon;
    background-image: url('/assets/images/icons/tick-inactive.svg');
    width: 14px;
    height: 14px;
}

.arrow-down-icon {
    @extend .icon;
    background-image: url('/assets/images/icons/arrow-down.svg');
    width: 14px;
    height: 14px;
}

.close-icon {
    @extend .icon;
    width: 17px;
    height: 18px;
    background-image: url("/assets/images/icons/close.svg");
}

.tickets-icon {
    @extend .icon;
    background-image: url("/assets/images/icons/close.svg");
}

.payments-icon {
    @extend .icon;
    background-image: url("/assets/images/icons/payments.svg");
}

.log-icon {
    @extend .icon;
    background-image: url("/assets/images/icons/close.svg");
}

.heart-icon {
    @extend .icon;
    width: 21px;
    height: 21px;
    background-image: url("/assets/images/icons/heart.svg");
}

.trash-icon {
    @extend .icon;
    width: 21px;
    height: 21px;
    background-image: url("/assets/images/icons/trash.svg");
}

// .no-content-icon {
//     @extend .icon;
//     width: 154px;
//     height: 92px;
//     background-image: url("/assets/images/icons/no-content.svg");
// }

.language-et-icon {
    @extend .icon;
    width: 17px;
    height: 17px;
    background-image: url("/assets/images/icons/flag-et.svg");
}

.language-en-icon {
    @extend .icon;
    width: 17px;
    height: 17px;
    background-image: url("/assets/images/icons/flag-en.svg");
}

.booster-icon {
    @extend .icon;
    width: 16px;
    height: 18px;
    background-image: url("/assets/images/icons/booster.svg");
}

.betting-inactive-icon {
    @extend .icon;
    width: 32px;
    height: 32px;
    background-image: url("/assets/images/icons/betting-inactive.svg");
  }

.results-inactive-icon {
    @extend .icon;
    width: 32px;
    height: 32px;
    background-image: url("/assets/images/icons/results-inactive.svg");
}

.betting-active-icon {
    @extend .icon;
    width: 32px;
    height: 32px;
    background-image: url("/assets/images/icons/betting-active.svg");
  }

.results-active-icon {
    @extend .icon;
    width: 32px;
    height: 32px;
    background-image: url("/assets/images/icons/results-active.svg");
}

/* */