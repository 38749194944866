@use "src/app/styles/breakpoints";

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  height: 100%;
  width: 100vw;
  max-width: var(--layout-max-width);
}

// .content {
//   display: flex;
//   flex-direction: column;
//   max-width: var(--content-max-width);
//   padding: var(--content-padding);
//   width: 100%;
// }

.inline-block {
  display: inline-block;
}

.separator {
  height: 1px;
  background: #363636;
  margin: 30px 0;
}

.subheader {
  border-bottom: 1px solid #665f62;
  padding-bottom: 7px;
  margin-bottom: 18px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 300;

  display: flex;

  .right {
    margin-left: auto;
  }
}

.card-header-top {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 0 20px;
  color: white;
  line-height: 24px;
  text-transform: uppercase;
}

form,
body {
  margin: 0;
  padding: 0;
}

.hidden {
  display: none !important;
}

.invisible {
  opacity: 0 !important;
  pointer-events: none !important;
  cursor: default !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-visible {
  overflow: visible;
}

.noscroll {
  overflow: hidden !important;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: var(--scrollbar-width);
  background-color: transparent;
}

::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}


::-webkit-scrollbar-thumb:hover {
  /*background: var(--dirty-purple);*/
  background: var(--scrollbar-thumb-hover-color);
}

// .col {
//   > {
//     toto-input,
//     toto-select {
//       margin-bottom: 15px;
//     }
//   }
// }


@media screen and (min-width: breakpoints.$md) {
  .mobile {
    display: none !important;
  }
}

@media screen and (max-width: breakpoints.$md) {

  .card-header-top {
    min-height: 50px;
    padding: 0 24px;
    margin-bottom: 12px;
  }

  .desktop {
    display: none !important;
  }

  .content {
    card:first-of-type {
      header.card-header {
        margin-top: 0 !important;
      }
    }
  }
}


.simple-row {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;
  max-width: 100%;
}

.row {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;
  // max-width: 100%;
  // overflow: auto;
  margin-left: calc(var(--col-x-spacing) * -1);
  margin-right: calc(var(--col-x-spacing) * -1);
}
 
// DESKTOP
@media screen and (min-width: breakpoints.$lg) {

  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      width: calc($i / 12) * 100%;

      padding-left: var(--col-x-spacing);
      padding-right: var(--col-x-spacing);
    }
  }
}

// TABLET
@media screen and (min-width: breakpoints.$sm) and (max-width: breakpoints.$lg) {

  @for $i from 1 through 12 {
    .col-md-#{$i} {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      width: calc($i / 12) * 100%;

      padding-left: var(--col-x-spacing);
      padding-right: var(--col-x-spacing);

      // @if($i !=12) {
      //   &:not(:first-of-type):not(:nth-of-type(3)) {
      //     padding-left: 9px;
      //   }
      //   &:not(:last-of-type) {
      //     padding-right: 9px;
      //   }
      // }

    }
  }
}



// MOBILE
@media screen and (max-width: breakpoints.$sm) {

  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      width: calc($i / 12) * 100%;
      padding-left: var(--col-x-spacing);
      padding-right: var(--col-x-spacing);
    }
  }
}

// @for $i from 1 through 12 {
//   .col-#{$i} {
//     display: flex;
//     flex-direction: column;
//     flex: 0 0 auto;
//     // -ms-flex: 1 1 calc($i / 12) * 100%;
//     // flex: 1 1 calc($i / 12) * 100%;
//     width: calc($i / 12) * 100%;
//   }
// }

@for $i from 0 through 100 {
  .ml#{$i} {
    margin-left: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .mr#{$i} {
    margin-right: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .mt#{$i} {
    margin-top: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .mb#{$i} {
    margin-bottom: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .p#{$i} {
    padding: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .pl#{$i} {
    padding-left: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .pr#{$i} {
    padding-right: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .pt#{$i} {
    padding-top: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .w#{$i} {
    width: $i * 1px;
  }
}

@for $i from 0 through 100 {
  .w#{$i}p {
    width: $i * 1%;
  }
}

@for $i from 0 through 100 {
  .h#{$i}p {
    height: $i * 1%;
  }
}